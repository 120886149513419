import https from "./https"

const customerLevelRuleApi = {
  getRules (organization, params) {
    const url = 'admin/organizations/' + organization.id  + '/customer-level-rules'
    const searchParams = new URLSearchParams(params);
    return https.get(`${url}?${searchParams}`);
  },
  getRule (organization, ruleId) {
    const url = 'admin/organizations/' + organization.id  + '/customer-level-rules/' + ruleId
    return https.get(url)
  },
  updateRule (organization, params) {
    const url = 'admin/organizations/' + organization.id  + '/customer-level-rules/' + params.id;
    return https.put(url, params)
  },
  removeRule (organization, ruleId) {
    const url = 'admin/organizations/' + organization.id + '/customer-level-rules/' + ruleId;
    return https.delete(url)
  },
  createRule (organization, params) {
    const url = 'admin/organizations/' + organization.id  + '/customer-level-rules'
    return https.post(url, params)
  },
}

export default customerLevelRuleApi
